import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import Expectations from "./expectations";
// import Team from "./team"
import triangles from "../../images/triangles.png";
import LogoImage from "../../images/Homebuyer Connect_H Logo.png";
function MainContent() {
  return (
    <React.Fragment>
      <Container className="text-center mt-md-0 pt-md-0 pt-lg-3 mt-lg-3">
        <Row>
          <Col lg={12}>
            <Image src={LogoImage} className="img-fluid" width="440px" />
          </Col>
          <Col lg={12} className="mt-5 text-start">
            <p style={{ wordSpacing: "2px", lineHeight: "1.4" }}>
              Homebuyer Connect by Sun West Mortgage Company is a revolutionary
              new way to buy a home! We&#39;ll connect you with a highly
              professional Real Estate partner (or use your own if you already
              have one) and one of our experienced local Market Managers, who
              together will guide you on your homebuying journey every step of
              the way.
              <br />
              <br />
              Sun West is a full service mortgage banker that has been helping
              homebuyers just like you since 1980 - across the nation in 48
              states, District of Columbia, Puerto Rico and the US Virgin
              Islands. Our commitment to our core values of people, experience,
              technology, and product stem from the values that Sun West was
              built on. It is this culture of excellence that has helped us
              grow, and even allowed us to weather through tough economic times
              and continue to offer you exceptional customer service.
              <br />
              <br />
              We strive to offer our customers not only competitive mortgage
              rates, but also a wide selection of mortgage products including
              Conventional, FHA, VA, Jumbo, Home Improvements and Reverse
              mortgages – we&#39;ll help you choose the perfect one for you!
            </p>
          </Col>
        </Row>
        <Expectations></Expectations>
      </Container>
      {/* <Team /> */}
      <Container fluid className="mt-2 hlongblue">
        <Container>
          <Row className="hero-contents justify-content-center">
            <Col className="px-0 text-center my-4" xs={12}>
              <h1 className="px-2 d-inline">
                <span className="luck-md baby-blue">Contact</span>
              </h1>
              <p
                className="d-inline h2"
                style={{
                  color: "#1C2253",
                  fontSize: "22px",
                  fontWeight: "bold",
                }}
              >
                Us today to learn more!
              </p>
            </Col>

            <Col className="text-center">
              <div className="pb-2">
                <Button
                  className="clrd-btn ms-0 px-4 font-weight-normal"
                  size="md"
                  as={Link}
                  to="/contact"
                >
                  Request Info
                </Button>
              </div>
            </Col>

            <Col xs={12} className="text-center">
              <Image
                className="triangleImage"
                src={triangles}
                style={{ transform: "scaley(-1) scalex(-1)" }}
              />
            </Col>
          </Row>
        </Container>
      </Container>
    </React.Fragment>
  );
}
export default MainContent;
