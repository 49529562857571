import { useState, useEffect, useCallback } from "react";
import axios from "axios";

const useBlogs = (body = {}) => {
    const [blogPosts, setBlogPosts] = useState(null);
    const [refresh, setRefresh] = useState(true);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    useEffect(() => {
        const getBlogs = async () => {
            setLoading(true);
            setError(false);
            try {
                const { data } = await axios.post(
                    // process.env.REACT_APP_BLOG_API
                    "https://c3lpelw90h.execute-api.us-west-1.amazonaws.com/default/swmc-blog-production",
                    body,
                    {
                        headers: { "Content-Type": "application/json" },
                    }
                );

                if (data) {
                    setBlogPosts(() => data || []);
                } else {
                    console.log("No data returned from the API.");
                }

                if (body?.type === "GET_BLOG_CATEGORY" && data.length <= 6) {
                    throw new Error("All data necessary not present");
                }
                if (body?.type === "GET_BLOG_POST" && !data?.post) {
                    throw new Error("Blog data not present");
                }
            } catch (err) {
                console.log(err);
                setError(true);
            } finally {
                setRefresh(false);
                setLoading(false);
            }
        };
        if (refresh) getBlogs();
    }, [refresh, body]);

    const handleRefresh = useCallback(() => setRefresh(true), []);

    return {
        blogPosts: blogPosts,
        handleRefresh: handleRefresh,
        loading: loading,
        error: error,
    };
};
export default useBlogs;