import React, {useState, useEffect} from 'react'
import { HiChevronDoubleUp } from "react-icons/hi";


export default function ScrollUpBtn() {
    const [ showGoTop, setShowGoTop ] = useState( false )

const handleVisibleButton = () => {
    setShowGoTop( window.pageYOffset > 300 )
}

const handleScrollUp = () => {
    window.scrollTo( { left: 0, top: 0, behavior: 'smooth' } )
}

useEffect( () => {
    window.addEventListener( 'scroll', handleVisibleButton )
}, [] )
  return (
    <div role="button" aria-label="Scroll to top of page" tabIndex="0" data-testid="react-scroll-up-button" className={ showGoTop ? '' : 'd-none' } onClick={ handleScrollUp } style={{background: 'rgb(0, 170, 228)', height: '50px', position: 'fixed', bottom: '20px', width: '50px', transition: 'opacity 0.5s ease-in-out 0s right', cursor: 'pointer', opacity: '1', right: '20px', zIndex: '1000', borderRadius: '30px'}}>
        <HiChevronDoubleUp color='white' size='2.5rem' className='arrowUp' />
    </div>
  )
}
