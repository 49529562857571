import React, { Component } from 'react';
import Topbar from '../components/aboutcomps/topbar';
import MainContent from '../components/aboutcomps/maincontent';
import ReactGA from 'react-ga';
// import TagManager from 'react-gtm-module';
import {Helmet} from "react-helmet";

// const tagManagerArgs = {
//     gtmId: 'GTM-NJX2WC6',
//     dataLayer: {
//       page: 'about'
//   },
//   dataLayerName: 'PageDataLayer'
//   }

export default class Pigeons extends Component {
    constructor() {
        super()
        this.state = {
        }
    }

    componentDidMount(){
        ReactGA.initialize('UA-187484893-1');
        ReactGA.pageview(window.location.pathname + window.location.search);

        // TagManager.initialize(tagManagerArgs)
        // TagManager.dataLayer(tagManagerArgs)
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>About | Homebuyer Connect</title>
                    <link rel="canonical" href="https://homebuyerconnect.com/about" />
                </Helmet>
                <Topbar></Topbar>
                <MainContent></MainContent>
            </React.Fragment>
        )
    }
}
