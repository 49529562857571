import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import "./localStyles.css"
function TopBar() {
    return (
        <>

            <Container fluid className="about-hero px-0">
                <div className="triangular h-100">
                    <Container className="h-100 d-flex align-items-center">
                        <Row className="hero-contents w-100">
                            <Col className="text-center mt-md-0 pt-md-0 pt-lg-5 hero-text mx-auto" lg={8}>
                                <span className="lucky light-blue">Connecting</span>
                                <p style={{ fontSize: "45px" }} className="d-inline h2"> You to the <br />  American Dream </p><br />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Container>
        </>
    );
}
export default TopBar;